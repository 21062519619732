















































































































import {
    Component,
    Vue
} from 'vue-property-decorator';
import Graph from '@/components/Pages/Home/Graph.vue'
import {
    Auth
} from '@/store/auth'
import {
    Core
} from '@/store/core'
import {
    User
} from '@/store/user'
import _ from 'lodash'
import {
    Web
} from '@/store/web'
@Component({
    components: {
        Graph
    },
})
export default class Home extends Vue {
    publicAgency: any = 41
    myAgency: any = 0
    private dashboard: any = null
    private user: any = {}
    private years: any = []
    private response: boolean = false
    $vs: any
    search: any = ''
    headers: any = [{
            text: 'ที่',
            value: 'index'
        },
        {
            text: 'บัญชีผู้ใช้งาน',
            value: 'fullname'
        },
        {
            text: 'การเข้าสู่ระบบ',
            value: 'register_type'
        },
    ]
    tab: any = 0

    public async created() {
        await Web.switchLoad(true)
        this.response = false
        try {
            this.user = await User.getUser();
            this.myAgency = (this.user.ext_link) ? this.user.ext_link.agency : 0
            await this.getDashboard()
            this.years = await Core.getHttp(`/api/ita/v2/year/`)
            for (let i = 0; i < this.years.length; i++) {
                let res = await Core.getHttp(`/api/ita/v1/rateresult/?rate__year=${this.years[i].id}&agency=${this.user.ext_link.agency}`)
                let rate = await Core.getHttp(`/api/ita/v2/rate/${this.years[i].id}/`)
                this.years[i].result = res.length
                this.years[i].rate = rate.length
            }
            this.$vs.notification({
                color: "#7d33ff",
                icon: '<i class="em em-writing_hand" aria-role="presentation" aria-label="BOOKMARK"></i>',
                title: `กรุณาทำแบบประเมิน ${(this.myAgency != this.publicAgency)?'IIT และ ':''} EIT `,
                text: `หากท่านยังไม่ได้ทำแบบประเมิน`
            })

        } catch (error) {

        }
        await Web.switchLoad(false)
        this.response = true
    }

    public async getDashboard() {
        this.dashboard = await Core.getHttp(`/api/ita/v2/dashboard/${this.user.ext_link.id}/`)
        try {
            this.dashboard.agency_passing_all = _.map(this.dashboard.agency_passing_all, (item: any, index: any) => {
                item.index = index + 1
                if (item.user) {
                    item.fullname = item.user.first_name + ' ' + item.user.last_name
                } else {
                    item.fullname = 'ไม่มีข้อมูล'
                }
                return item
            })
        } catch (error) {

        }
        try {
            this.dashboard.agency_user_all = _.map(this.dashboard.agency_user_all, (item: any, index: any) => {
                item.index = index + 1
                if (item.user) {
                    item.fullname = item.user.first_name + ' ' + item.user.last_name
                } else {
                    item.fullname = 'ไม่มีข้อมูล'
                }
                return item
            })
        } catch (error) {

        }
    }

}
