











































import {Component, Prop, Vue} from 'vue-property-decorator';
import { Auth } from '@/store/auth'
import { Core } from '@/store/core'
import { User } from '@/store/user'
import _ from "lodash";
@Component({
    components: {

    },
})
export default class Graph extends Vue {

  agency:any = 0


  year:any = '2564'
  responseDetail:boolean = false
  lists:any = null
  all:any = null
  response:boolean = false
    series: any = []
    chartOptions: any = {
        chart: {
            width: 380,
            type: 'polarArea'
        },
        stroke: {
            colors: ['#fff']
        },

        fill: {
            opacity: 0.8
        },
        labels: ['การปฏิบัติหน้าที่', 'คุณภาพการดำเนินงาน', 'การใช้อำนาจ', 'การใช้ทรัพย์สินของราชการ', 'การใช้งบประมาณ', 'การปรับปรุงระบบการทำงาน', 'การแก้ไขปัญหาการทุจริต', 'ประสิทธิภาพการสื่อสาร', 'การเปิดเผยข้อมูล', 'การป้องกันการทุจริต'],
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 400
                },
            }
        }]
    }
    user:any = null
  years:any = null
    public async created() {
      this.user = await User.getUser();
      this.agency = (this.user.ext_link) ? this.user.ext_link.agency : 0
      this.years = await Core.getHttp(`/api/ita/v1/year/`)
      await this.loadAll();
      await this.loadLists();
      await this.generateGraph();
      this.response = true
    }

    async loadData(){
      this.response = false

      await this.loadLists();
      await this.generateGraph();
      this.response = true
      await this.loadAll();
    }

  async loadAll(){
    let data =  await Core.getHttp(`/api/report/v1/reportall/?year=${this.year}&agency=${this.agency}`)
    if(data.length > 0){
      this.all = data[0]
    }else{
      this.response = false;
    }
  }

  async loadLists(){
    let data =  await Core.getHttp(`/api/report/v1/reportdetail/?year=${this.year}&agency=${this.agency}`)
    this.lists = _.orderBy(data,'order','asc') ;
  }

  async generateGraph(){
    let categories = _.map(this.lists, 'name');
    let score = _.map(this.lists, 'score');
    this.series = score
    this.chartOptions.labels = categories

    console.log('[DATA GRAPH]',categories,score)

  }

}
